import formatPrice from '../helpers/formatPrice';
import { splitNumber } from '../helpers/common';
import { api, config } from '@detox/actions';
import { store } from '../store/createStore';
import isMobileSharePlan from './is-mobile-share-plan';
import createDescription from './create-description';
import cartConstants from '../constants/cart';
import { trans as t } from '../helpers/localisation';
import { ORDER, CART } from '../constants';
import isFeatureFlagEnabled from './feature-flags';
import CONSTANTS from '../constants/common';
import { RED_PHASE, CP9_FLOW } from '../types/featureFlag.types';
import { formatDate } from '../components/Fulfilment/helpers';
import { CP_ERROR_CODES } from '../config/common';
import { getAdditionalBillDescription } from './common';

const {
  isAppleCare,
  isDeviceCare,
  sortOfferReccuringPrice
} = api.mcss.helpersCart;
const { VOUCHER_REDEMPTION_TYPE, ROAMING_BUNDLE, ACTION_TYPE } = CART;

/* TODO: it seems there are different ID for AppleCare, clarify with business and add this to detox/actions */
const APPLECARE_PRODUCT_SPEC_CONTAINMENT_ID = '8472832';

export const defaultGenericErrorModal = {
  title: 'Error',
  errorText:
    'Sorry, you are unable to proceed with your order online. For more information, please call 1688 or or proceed to your nearest Singtel shop.',
  primaryButtonText: null,
  primaryButtonLink: null,
  secondaryButtonText: null,
  secondaryButtonLink: null,
  errorCode: ''
};

export const getAllDiscountItems = ({ tradeIn, vouchers, promotions }) => {
  const items = [];
  if (tradeIn) {
    const {
      brand,
      model,
      tradeInBo: {
        productID,
        name,
        totalOneTimeCalculatedPrice,
        isSopDeviceOneTimeRebate
      }
    } = tradeIn;

    if (!isSopDeviceOneTimeRebate) {
      items.push({
        id: productID,
        name: name,
        description: `${brand} ${model}`,
        totalPrice: {
          finalAmount: totalOneTimeCalculatedPrice.finalAmount,
          finalAmountIncludingTax:
            totalOneTimeCalculatedPrice.finalAmountIncludingTax,
          finalTaxAmount: totalOneTimeCalculatedPrice.finalTaxAmount
        }
      });
    }
  }

  if (vouchers?.length) {
    vouchers.forEach(item => {
      const { name, description, idX8, checkoutPrice } = item;

      items.push({
        id: idX8,
        name: name,
        description: description,
        totalPrice: {
          finalAmount: checkoutPrice.finalAmount,
          finalAmountIncludingTax: checkoutPrice.finalAmountIncludingTax,
          finalTaxAmount: checkoutPrice.finalTaxAmount
        }
      });
    });
  }

  if (promotions?.length) {
    promotions.forEach(item => {
      const {
        label,
        productID,
        finalAmount,
        finalAmountIncludingTax,
        finalTaxAmount
      } = item;

      items.push({
        id: productID,
        name: label,
        description: label,
        totalPrice: {
          finalAmount,
          finalAmountIncludingTax,
          finalTaxAmount
        }
      });
    });
  }

  return items;
};

export const getFreebiesStock = (mtposStock = [], freebies = []) => {
  let availableFreebies = [];
  let outOfStockFreebies = [...freebies];

  const freebiesSkuIds = freebies.map(freebie => freebie.skuId);
  const freebiesStock = mtposStock.filter(equipment =>
    freebiesSkuIds.includes(equipment.itemCode)
  );
  //Check if we miss free gifts in api response
  /* outOfStockFreebies.push(mtposStock.filter(equipment =>
    !freebiesSkuIds.includes(equipment.itemCode)
  )); */

  // Check if we miss free gifts in api response
  //if (freebiesSkuIds.length !== freebiesStock.length) return true;

  // Check if free gift is out of stock
  for (let index = 0; index < freebiesStock.length; index++) {
    const currentFreebie = freebiesStock[index];
    if (currentFreebie.availableQty > 0 || currentFreebie.status !== 'NA') {
      availableFreebies.push(
        freebies.find(freebie => freebie.skuId === currentFreebie.itemCode)
      );
      outOfStockFreebies = outOfStockFreebies.filter(
        freebie => freebie.skuId !== currentFreebie.itemCode
      );
    }
    /*  else{
      availableFreebies.push(freebies.find(freebie =>
        freebie.skuId === currentFreebie.itemCode || !freebiesSkuIds.includes(currentFreebie.itemCode)));
    } */
  }
  return { availableFreebies, outOfStockFreebies };
};

export const rearrangeNewAndFreeRecontractAddons = monthlyBills => {
  let newAddOns = [];
  let previousContractAddOns = [];
  let freeAddOns = [];
  monthlyBills.forEach(item => {
    if (Number(item?.price) === 0) {
      freeAddOns.push(item);
    } else if (item.removableFromCart === false && Number(item?.price) >= 0) {
      previousContractAddOns.push(item);
    } else {
      newAddOns.push(item);
    }
  });
  if (freeAddOns.length || previousContractAddOns.length) {
    newAddOns.push({
      description:
        'The add-on(s) below are FREE or from your previous contract. You can manage these \nadd-ons in My Singtel App or "My account" after order completion.'
    });
  }

  return [...newAddOns, ...previousContractAddOns, ...freeAddOns];
};

export const overrideRemovableAddons = (monthlyBills, removeAddonIDs = '') => {
  const removeAddonIDsList = (
    ('' + removeAddonIDs).trim().split(',') || []
  ).filter(Boolean);

  return monthlyBills?.map(monthlyBill => {
    const {
      cID = '',
      productID = '',
      simpleProductSpecID = '',
      removableFromCart
    } = monthlyBill;
    const byPassRemoveAddon =
      removeAddonIDsList.includes(cID) ||
      removeAddonIDsList.includes(simpleProductSpecID) ||
      removeAddonIDsList.includes(productID);

    return {
      ...monthlyBill,
      removableFromCart:
        removableFromCart && removeAddonIDsList.length > 0
          ? !byPassRemoveAddon
          : removableFromCart
    };
  });
};

export const getCartDisplayedData = data => {
  const {
    mobile: {
      serviceId,
      device,
      promotions: discountStock,
      freebies,
      offersWithOneTimePrice,
      offersWithRecurringPrice,
      deliveryCharges
    },
    checkOutPriceBreakdown,
    oneTimePriceBreakdown,
    originUpfrontPriceBreakdown,
    originUpfrontPriceCanBeOffset,
    originUpfrontPriceCanBeOffsetForTradeIn,
    tradeInDetails,
    accessories,
    serviceRequiredDate,
    voucher,
    vendorName,
    isIppOrder,
    tradeInProductSpecContainmentId
  } = data;
  const allDiscountItems = getAllDiscountItems({
    tradeIn: tradeInDetails,
    vouchers:
      voucher.redemptionType !== VOUCHER_REDEMPTION_TYPE.REBATE
        ? voucher.lists
        : [],
    promotions: discountStock?.promotions
  });

  /* total calculated save price from tradein, voucher, promotion */
  const totalSave = allDiscountItems.reduce(
    (acc, cur) => acc + cur.totalPrice.finalAmountIncludingTax,
    0
  );

  /* sort discount stocks */
  discountStock.promotions.sort((a, b) => a.label.localeCompare(b.label));

  let appleCare;
  let promotionsBo = [];
  if (discountStock) {
    const { promotions } = discountStock;
    promotionsBo = promotions;
  }

  /**
   * To filter out apple care, delivery, and discount stock from 'additional to first bill' section.
   * Notes: Apple care, delivery and discount stock are displayed under upfront fee today's bill.
   */
  const oneTimeBoItems = offersWithOneTimePrice?.filter(item => {
    /* Filter apple care and retrieve apple care bo */
    if (
      isAppleCare(item.productSpecContainmentID) ||
      item.productSpecContainmentID === APPLECARE_PRODUCT_SPEC_CONTAINMENT_ID
    ) {
      appleCare = item;
      return false;
    }
    const isSopDeviceRebate =
      item?.productSpecPricing?.childPricingSchema?.id ===
      config.simOnlyOneTimeBillRebatePricingId;
    /* filter tradein & voucher */
    if (
      !isSopDeviceRebate &&
      (item.productSpecContainmentID === tradeInProductSpecContainmentId ||
        item.productSpecContainmentID ===
          config.voucherProductSpecContainmentId)
    ) {
      return false;
    }

    /* Filter discount */
    if (
      promotionsBo &&
      promotionsBo.some(
        promo =>
          promo.billingOfferID &&
          promo.stockCode &&
          promo.billingOfferID === item.billingOfferID &&
          promo.stockCode === item.stockCode
      )
    ) {
      return false;
    }

    /* Filter delivery */
    if (
      deliveryCharges &&
      deliveryCharges.billingOfferID &&
      deliveryCharges.billingOfferID === item.billingOfferID &&
      deliveryCharges.name === item.name
    ) {
      return false;
    }

    return true;
  });

  /* items for addition section */
  const firstBills = [...oneTimeBoItems]
    .filter(Boolean)
    .sort((a, b) => a.description.localeCompare(b.description));

  /* items for monthly subscription section
   * filter Existing MobileSWOP and remain newly added MobileSWOP and it's promo if any
   */
  const newMobileSwop = offersWithRecurringPrice.find(
    item => isDeviceCare(item) && item.removableFromCart
  );
  let monthlyBills = offersWithRecurringPrice.filter(
    item =>
      !isDeviceCare(item) ||
      (newMobileSwop && newMobileSwop.productID === item.productID)
  );

  monthlyBills = sortOfferReccuringPrice(monthlyBills);
  monthlyBills = rearrangeNewAndFreeRecontractAddons(monthlyBills);

  return {
    deviceName: device?.name,
    device: device?.model,
    deviceCharges: device?.deviceCharges?.checkoutPrice || 0,
    /* repaymentChanges is not typo, it's what in detox actions */
    repaymentCharges: device?.repaymentChanges?.checkoutPrice || 0,
    appleCare: appleCare,
    deliveryCharges: deliveryCharges,
    freebies: freebies,
    discount: discountStock,
    monthlyBills: monthlyBills,
    totalCheckOutPrice: checkOutPriceBreakdown.finalAmountIncludingTax,
    totalOneTimePrice: oneTimePriceBreakdown.finalAmountIncludingTax,
    totalOriginUpfrontPrice:
      originUpfrontPriceBreakdown.finalAmountIncludingTax,
    totalOriginUpfrontPriceCanBeOffset:
      originUpfrontPriceCanBeOffset?.finalAmountIncludingTax,
    totalOriginUpfrontPriceCanBeOffsetForTradeIn:
      originUpfrontPriceCanBeOffsetForTradeIn?.finalAmountIncludingTax,
    firstBills: firstBills,
    vouchers: voucher.lists,
    voucher,
    totalSave,
    serviceId,
    tradeInDetails,
    accessories,
    ippDetails: device?.ippDetails,
    serviceRequiredDate,
    vendorName,
    isIppOrder
  };
};

export const isRoamingBundleCharges = item => {
  return item?.name?.toUpperCase().includes(ROAMING_BUNDLE);
};

export const getShoppingCartDataModel = options => {
  const {
    cartOrder,
    selectedProduct,
    orderType,
    mainLines,
    userSelectedServiceId,
    plans,
    userProducts,
    callbacks,
    existingOrder,
    cisUser,
    hasAction = true,
    callFromOrderSummary = false,
    freebiesStockData,
    productVariantBySku = {},
    unRemovableAddons
  } = options;

  let dataModel = {};

  if (!cartOrder || Object.keys(cartOrder).length == 0) {
    return dataModel;
  }
  const isRrpIppOrder = Boolean(cartOrder?.accessories?.[0]?.ippDetails);

  if (cartOrder.device && selectedProduct) {
    const {
      imageGallery,
      title,
      colour,
      size,
      isPreOrder,
      isBackOrder
    } = selectedProduct;
    const productImage = imageGallery.find(img => img.name === colour);

    if (!('upfront' in dataModel)) {
      dataModel = { ...dataModel, upfront: { dataSource: [] } };
    }
    dataModel.upfront.dataSource.push({
      type: 'device',
      ribbon: isPreOrder || isBackOrder ? t('PRE-ORDER') : '',
      name: `${title} ${colour}, ${size}`,
      price: cartOrder.deviceCharges,
      action: hasAction && ACTION_TYPE.CHANGE,
      modalName: 'CHANGE_PRODUCT',
      heroImage: {
        imageFile: {
          childImageSharp: productImage?.images[0]?.image?.childImageSharp
        },
        altText: null
      }
    });
  }

  if (cartOrder.appleCare) {
    if (!('upfront' in dataModel)) {
      dataModel = { ...dataModel, upfront: { dataSource: [] } };
    }
    dataModel.upfront.dataSource.push({
      ...cartOrder.appleCare,
      name: cartOrder.appleCare.name,
      description: cartOrder.appleCare.description,
      price: cartOrder.appleCare.price,
      action:
        hasAction && cartOrder.appleCare.removableFromCart && ACTION_TYPE.REMOVE
    });
  }
  if (cartOrder?.freebies?.length > 0) {
    const freebiesStock = getFreebiesStock(
      freebiesStockData || store.getState().fulfillment?.mtposStock?.data,
      cartOrder.freebies
    );
    if (!('upfront' in dataModel)) {
      dataModel = { ...dataModel, upfront: { dataSource: [] } };
    }
    if (freebiesStock.availableFreebies.length > 0) {
      freebiesStock.availableFreebies.forEach(freebie => {
        dataModel.upfront.dataSource.push({
          ...freebie,
          name: freebie.name,
          price: 'FREE'
        });
      });
    }
    if (freebiesStock.outOfStockFreebies.length > 0) {
      dataModel = { ...dataModel, outOfStockItem: { dataSource: [] } };
      freebiesStock.outOfStockFreebies.forEach((freebie, index) => {
        dataModel.outOfStockItem.dataSource.push({
          id: index + 1,
          name: freebie.name,
          productID: freebie.productID
        });
      });
    }
  }

  if (cartOrder?.accessories?.length > 0) {
    if (!('upfront' in dataModel)) {
      dataModel = { ...dataModel, upfront: { dataSource: [] } };
    }
    cartOrder.accessories.forEach(accessory => {
      const variant = productVariantBySku[accessory.skuId];
      let action =
        hasAction && accessory.removableFromCart && ACTION_TYPE.REMOVE;
      if (isRrpIppOrder) {
        action = ACTION_TYPE.CHANGE;
      }

      dataModel.upfront.dataSource.push({
        ...accessory,
        name: accessory.name,
        price: accessory.checkoutPrice.finalAmountIncludingTax,
        action,
        ...(variant && {
          image: {
            ...variant.smallImage,
            src: process.env.GATSBY_AEM_URL + variant.smallImage.src
          }
        })
      });
    });
  }

  const allDiscount = [];

  if (cartOrder?.discount?.promotions?.length) {
    cartOrder.discount.promotions.forEach(discountItem => {
      allDiscount.push({
        id: discountItem.productID,
        name: discountItem.label,
        price: discountItem.finalAmountIncludingTax
      });
    });
  }

  if (
    cartOrder?.voucher?.lists?.length > 0 &&
    cartOrder?.voucher?.redemptionType !== VOUCHER_REDEMPTION_TYPE.REBATE
  ) {
    cartOrder.voucher.lists.forEach(voucher => {
      allDiscount.push({
        id: voucher.idX8,
        name: voucher.name,
        price: voucher.checkoutPrice.finalAmountIncludingTax
      });
    });
  }

  const tradeInItem = cartOrder?.tradeInDetails?.tradeInBo || {};
  if (
    !tradeInItem.isSopDeviceOneTimeRebate &&
    Object.keys(tradeInItem).length
  ) {
    allDiscount.push({
      id: tradeInItem?.billingOfferID,
      name: t('TRADE_IN'),
      price: tradeInItem?.price
    });
  }

  /* Discounts */
  if (allDiscount.length > 0) {
    dataModel.discount = {
      totalAmount: 0,
      dataSource: []
    };
    allDiscount.forEach(item => {
      dataModel.discount.totalAmount += Math.abs(item.price);
      dataModel.discount.dataSource.push(item);
    });
  }

  if (cartOrder?.repaymentCharges > 0) {
    if (!('upfront' in dataModel)) {
      dataModel = { ...dataModel, upfront: { dataSource: [] } };
    }
    dataModel.upfront.dataSource.push({
      name: t('EARLY_REPAYMENT_FEE'),
      price: cartOrder?.repaymentCharges
    });
  }

  if (cartOrder?.deliveryCharges) {
    if (!('upfront' in dataModel)) {
      dataModel = { ...dataModel, upfront: { dataSource: [] } };
    }
    dataModel.upfront.dataSource.push({
      name: 'Standard Delivery',
      price: cartOrder?.deliveryCharges.price
    });
  }

  /* Monthly bills */
  if (cartOrder?.serviceId || cartOrder?.selectedPlan) {
    if (!('monthly' in dataModel)) {
      dataModel = { ...dataModel, monthly: { dataSource: [] } };
    }
    addMonthlyBillForMobileSection({
      dataModel,
      cartOrder,
      orderType,
      mainLines,
      userSelectedServiceId,
      plans,
      userProducts,
      callbacks,
      callFromOrderSummary
    });
  }

  const structureMonthlyBillData = bills => {
    /* If roaming charges are existed need to filter out from monthly bills*/
    const roamingChargesAvailable =
      bills && bills.filter(item => isRoamingBundleCharges(item));
    if (roamingChargesAvailable && roamingChargesAvailable.length > 0) {
      bills = bills.filter(item => !isRoamingBundleCharges(item));
    }

    bills.forEach(item => {
      const isRemovable = !unRemovableAddons?.includes(item.cID);
      dataModel.monthly.dataSource.push({
        ...item,
        name:
          item.price !== undefined && (cisUser || item.price < 0)
            ? item.name || item.description
            : item.description,
        description: null,
        price: isNaN(item.price)
          ? undefined
          : item.price != 0
          ? t('PRICE_PER_MONTH', {
              price: formatPrice(item.price)
            })
          : 'FREE',
        action:
          isRemovable &&
          hasAction &&
          item.removableFromCart &&
          ACTION_TYPE.REMOVE
      });
    });
  };

  if (cartOrder?.ippDetails) {
    dataModel.monthly.dataSource.push({
      name: cartConstants.DEVICE_IPP_TITLE,
      description: t('CART.INSTALMENT_TERM_DESCRIPTION', {
        monthlyTerm: cartOrder?.ippDetails?.numberOfInstalment
      }),
      price: t('PRICE_PER_MONTH', {
        price: formatPrice(
          cartOrder?.ippDetails?.ippCharges?.finalAmountIncludingTax
        )
      })
    });
  }

  if (!isRrpIppOrder && cartOrder?.monthlyBills?.length) {
    if (!('monthly' in dataModel)) {
      dataModel = { ...dataModel, monthly: { dataSource: [] } };
    }

    structureMonthlyBillData(cartOrder.monthlyBills);
  }
  if (isRrpIppOrder && cartOrder?.accessories?.length) {
    const accessory = cartOrder.accessories[0];

    dataModel.monthly.dataSource.push({
      name: t('CART.PRODUCT_INSTALMENT_PLAN.TEXT'),
      description: t('CART.INSTALMENT_TERM_DESCRIPTION', {
        monthlyTerm: accessory.monthlyTerm
      }),
      price: t('PRICE_PER_MONTH', {
        price: formatPrice(accessory.recurringPrice.finalAmountIncludingTax)
      }),
      action: ACTION_TYPE.CHANGE
    });
    dataModel.note = t('DROP_SHIPPING.IPP.MONTHLY_BILL.NOTE');
  }

  if (
    isFeatureFlagEnabled(RED_PHASE) &&
    cartOrder?.voucher?.redemptionType === VOUCHER_REDEMPTION_TYPE.REBATE &&
    cartOrder?.voucher?.lists?.length
  ) {
    dataModel.monthly.dataSource.push({
      name: cartOrder.voucher.lists[0].name,
      description: `Rebate 24 months`,
      price: t('PRICE_PER_MONTH', {
        price: formatPrice(cartOrder.voucher.recurringAmount)
      })
    });
  }
  /* Addition to first bill */
  if (cartOrder?.firstBills?.length) {
    dataModel = {
      ...dataModel,
      addition: {
        dataSource: cartOrder.firstBills.map(item => ({
          ...item,
          name:
            item.price < 0
              ? item.name
              : getAdditionalBillDescription(item.description),
          description:
            item.description === 'Early Repayment Fee' && existingOrder?.device
              ? `This refers to the outstanding instalment balance for your ${existingOrder?.device}`
              : null,
          price: item.price !== 0 ? item.price : 'FREE'
        })),
        totalAmount: cartOrder?.totalOneTimePrice
      },
      note:
        'Pay only for your new device and delivery fee (if applicable) today. For your convenience, other fees and charges will be added to your first bill.'
    };
  }

  /* Total */
  if ('totalCheckOutPrice' in cartOrder) {
    const originUpfrontPrice = cartOrder.totalOriginUpfrontPrice || 0;
    const checkoutPrice = cartOrder?.totalCheckOutPrice || 0;
    const totalSave = cartOrder?.totalSave || 0;
    const beforeDiscountAmount =
      originUpfrontPrice > checkoutPrice ? originUpfrontPrice : 0;

    dataModel = {
      ...dataModel,
      total: {
        payTotalAmount: checkoutPrice,
        beforeDiscountAmount: beforeDiscountAmount,
        saveAmount: Math.abs(totalSave)
      }
    };
  }

  const callBarringNote = isRrpIppOrder ? '' : t('CALL_BARRING_SERVICE_MSG');
  const availableCallBarringNote = callBarringNote
    ? { note: callBarringNote }
    : {};
  const notes = dataModel.note
    ? {
        note: [dataModel.note, callBarringNote].filter(Boolean)
      }
    : availableCallBarringNote;

  dataModel = {
    ...dataModel,
    ...notes
  };

  return dataModel;
};

export const filterMSMainlines = (mainlines = [], plans = []) => {
  return Object.keys(mainlines).filter(serviceId => {
    const mainline = mainlines[serviceId];
    return plans.some(
      plan =>
        plan.planId === mainline.implPlanDetails?.planId &&
        plan.mainlineGroup?.toUpperCase() === CONSTANTS.AEM_MS_MAINLINE_GROUP
    );
  });
};

export const addMonthlyBillForMobileSection = ({
  dataModel,
  cartOrder,
  orderType,
  mainLines,
  userSelectedServiceId,
  plans,
  userProducts,
  callbacks,
  callFromOrderSummary
}) => {
  const isMobileShare =
    cartOrder?.selectedPlan && isMobileSharePlan(cartOrder?.selectedPlan);
  const selectedService = cartOrder?.selectedService;
  const isMSRecontract = orderType === ORDER.TYPE.RECON;
  let group = {
    type: 'group',
    dataSource: []
  };

  if (cartOrder?.serviceId) {
    let numberOrder;
    const serviceRequiredDate = cartOrder?.serviceRequiredDate;
    numberOrder = {
      type: 'mobile-number',
      name: t(cartOrder?.vendorName ? 'CART.CHARGE_TO_MOBILE' : 'CART.MOBILE', {
        serviceId: splitNumber(cartOrder.serviceId)
      }),
      description: serviceRequiredDate
        ? t('SERVICE_REQUEST_DATE', [
            formatDate(new Date(serviceRequiredDate), 'dd MMM yyyy')
          ])
        : null
    };

    if (
      !callFromOrderSummary &&
      (orderType === ORDER.TYPE.NEW || orderType === ORDER.TYPE.MS_NEW)
    ) {
      numberOrder.action = cartConstants.ACTION_TYPE.CHANGE;
      numberOrder.modalName = cartConstants.CHANGE_NUMBER;
    }

    group.dataSource.push(numberOrder);
  }

  if (cartOrder?.selectedPlan) {
    const filteredMainLines = filterMSMainlines(mainLines, plans);
    const numberOfMainLine = filteredMainLines ? filteredMainLines.length : 0;
    const selectedMainline = mainLines?.[userSelectedServiceId];
    const selectedMainlineNumber = isMobileShare
      ? isMSRecontract
        ? selectedService?.serviceId
        : selectedMainline?.mainLineMSISDN
      : undefined;
    const shouldShowChangeButtonForMobileShare =
      isMobileShare &&
      selectedMainline &&
      numberOfMainLine > 1 &&
      !isMSRecontract;

    let modalData = {};
    if (isMobileShare && filteredMainLines) {
      let normalizedMainLines = filteredMainLines.map(serviceId => {
        const mainLine = mainLines[serviceId];
        const { mainLineMSISDN, implPlanDetails } = mainLine;

        const service = userProducts?.find(
          product => product.serviceId === mainLine.mainLineMSISDN
        );

        let planDescription = '';
        const contractPlan = plans?.find(
          plan => plan.basePriceSchemaId === service?.plan?.catalogItemID
        );
        if (contractPlan) {
          planDescription = createDescription({
            data: contractPlan.data,
            talktime: contractPlan.talktime,
            sms: contractPlan.sms
          });
        }

        return {
          id: mainLineMSISDN,
          name: splitNumber(mainLineMSISDN),
          description: implPlanDetails.name,
          extraText: planDescription
        };
      });

      modalData = {
        selectionModalData: {
          modalTitle: t('CHOOSE_MAINLINE'),
          defaultSelectedItemId: userSelectedServiceId,
          items: normalizedMainLines,
          actions: [
            {
              text: t('ACTION_TEXT_PROCEED'),
              onClick: callbacks?.handleChangeMainLine
            },
            {
              text: t('ACTION_TEXT_CANCEL'),
              secondary: true
            }
          ]
        }
      };
    }

    if (isMobileShare && selectedMainlineNumber && !isMSRecontract) {
      group.dataSource.push({
        description: t('MAIN_MOBILE_ACCOUNT', [
          splitNumber(selectedMainlineNumber)
        ]),
        action: shouldShowChangeButtonForMobileShare
          ? cartConstants.ACTION_TYPE.CHANGE
          : undefined,
        modalName: cartConstants.SELECTION_MODAL,
        ...modalData
      });
    }

    let planPrice = cartOrder.selectedPlan.price;
    let planDescription = cartOrder.selectedPlan.description;

    /* If roaming is existed display both plan charges with roaming charges into one common charge */
    const roamingChargesAvailable =
      cartOrder?.monthlyBills &&
      cartOrder?.monthlyBills.filter(item => isRoamingBundleCharges(item));
    if (roamingChargesAvailable && roamingChargesAvailable.length > 0) {
      planPrice = planPrice + roamingChargesAvailable[0].price;
      planDescription =
        planDescription + ' + ' + roamingChargesAvailable[0].description;
    }

    group.dataSource.push({
      name: cartOrder.selectedPlan.name,
      description: planDescription,
      price: `${formatPrice(planPrice)}/mth`,
      action: !callFromOrderSummary ? cartConstants.ACTION_TYPE.CHANGE : null,
      modalName: cartConstants.CHANGE_PLAN,
      ...modalData
    });
  }

  dataModel.monthly.dataSource.push(group);
};

/**
 * Get the mapping AEM error message to display based on error data.
 * If a mapping error was not found, this will return the generic AEM error message.
 *
 * @param {[Array | { checkoutErrorMessage: String }]} checkoutErrorData -
 *  Checkout error data could be an array which contains an object with bccErrorCode OR
 *  Checkout error data could be an object which has a custom checkout error code matching
 *    with AEM error code
 * @param {Array} allErrorMessages - All error messages from AEM
 * @return {Object} - Returns the mapped AEM error data object
 */
export const getCartError = (checkoutErrorData, allErrorMessages) => {
  let mappedError = null;
  let checkoutErrorCode = null;

  if (Array.isArray(checkoutErrorData)) {
    checkoutErrorCode =
      checkoutErrorData?.[0]?.parameters?.[0]?.value?.stringValue;
  } else {
    checkoutErrorCode = checkoutErrorData?.checkoutErrorCode;
  }

  mappedError = allErrorMessages?.find(errorObj => {
    const isMatchingWithBccErrorCode =
      errorObj.bccErrorCode &&
      checkoutErrorCode?.endsWith(errorObj.bccErrorCode);

    const isMatchingWithAemErrorCode = errorObj.errorCode === checkoutErrorCode;

    return isMatchingWithBccErrorCode || isMatchingWithAemErrorCode;
  });

  // Shows Generic Error if CP9 Feature Flag is false still BE response 50023/50021
  if (
    !isFeatureFlagEnabled(CP9_FLOW) &&
    Object.values(CP_ERROR_CODES).includes(mappedError?.bccErrorCode)
  ) {
    return getGenericError(allErrorMessages);
  }

  return mappedError || getGenericError(allErrorMessages);
};

/**
 * Get the generic AEM error message
 *
 * @param {Array} allErrorMessages - All error messages from AEM
 * @return {Object} - Returns the generic AEM error message
 */
const getGenericError = allErrorMessages => {
  const genericError = allErrorMessages?.find(errorObj => {
    return errorObj.errorCode === 'GENERIC_ERROR';
  });

  return genericError;
};

/**
 * Get the normalized cart error data model. If the error title has any
 *  placeholder texts, it will also be replaced.
 *
 * @param {Object} error - Mapped AEM error object
 * @param {Object} placeholderValues - Error message will have placeholder texts which
 *  needs to be replaced. This parameter will have the placholder text as the key and
 *  the placeholder value as the value in the object.
 * @return {Object} - Returns the normalized cart error data model
 */
export const getCartErrorDataModel = (error, placeholderValues = {}) => {
  let primaryButtonText = null;
  let primaryButtonLink = null;
  if (error?.enableCTA && error?.ctaActionType === 'primary') {
    primaryButtonText = error.ctaText;
    primaryButtonLink = error.ctaURL;
  }

  let secondaryButtonText = null;
  let secondaryButtonLink = null;
  if (error?.enableCTA && error?.ctaActionType === 'secondary') {
    secondaryButtonText = error.ctaText;
    secondaryButtonLink = error.ctaURL;
  }

  let title = error?.title;
  // Replace the placeholder texts in the error title
  for (const placeholder in placeholderValues) {
    if (title?.indexOf(`<${placeholder}>`) > -1) {
      const placeholderRegex = new RegExp(`<${placeholder}>`, 'g');
      if (placeholderValues[placeholder]) {
        title = title.replace(placeholderRegex, placeholderValues[placeholder]);
      }
    }
  }

  let footerList =
    error?.footerLinks?.length > 0 ? error.footerLinks : undefined;

  let cartErrorDataModel = null;
  if (error) {
    cartErrorDataModel = {
      title,
      errorText: error?.description,
      primaryButtonText,
      primaryButtonLink,
      secondaryButtonText,
      secondaryButtonLink,
      footerList,
      errorCode: error?.bccErrorCode
    };
  }
  return cartErrorDataModel;
};

export const setFreebieStatus = (removedFreebies = [], freebies = []) => {
  const freebiesWithStatus = freebies?.map(freebie => {
    const unavailable = removedFreebies.includes(freebie.skuId);
    return {
      ...freebie,
      unavailable
    };
  });
  return freebiesWithStatus;
};

export const hasSilverDiscountInfoError = (errorMessages = []) => {
  return errorMessages.some((errorMessage = {}) => {
    const errorMessageText = (errorMessage.text || '').toLowerCase();

    return (
      errorMessageText.includes('only 1 senior discounted line') ||
      errorMessageText.includes('b11')
    );
  });
};
