/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlayComponent from '../LoadingOverlay';
import { getUrlParams } from '@lux/helpers';
import {
  ADDITIONAL_VERIFY_ERROR,
  authActions,
  cartActions,
  errorScenarioActions,
  NEW_CUSTOMER_TO_MOBILE,
  NEW_NEW_CUSTOMER,
  OTP_EXCEED_LIMIT_ERROR,
  OTP_GENERIC_ERROR,
  OTP_MISMATCH_ERROR,
  OTP_RESEND_SUCCESS,
  OTP_SENT,
  OTP_TIMEOUT_ERROR,
  OTP_VERIFIED,
  OTP_VERIFY_ERROR,
  userActions,
  WORRY_FREE_ACCOUNT_VERIFIED,
  WORRY_FREE_FA_CHECK
} from '@detox/actions';

import { Verify } from '@common-modules/registration-checkout';
import GenericError from '../ShoppingCart/GenericError';
import CPModal from '../Checkout/CPModals';

import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import { flattenNodes } from '../../helpers/common';
import {
  defaultGenericErrorModal,
  getCartError,
  getCartErrorDataModel
} from '../../helpers/cart';
import getAemComponentByName from '../../helpers/getAemComponentByName';
import CHECKOUT_CONSTANT from '../../constants/checkout';
import ORDER_CONSTANT from '../../constants/order';
import CONSTANTS from '../../constants/common';
import useTranslate from '../../hooks/useTranslation';

import { navigate } from 'gatsby';
import ImageMsta from '../../assets/images/msta.png';
import ImageStBill from '../../assets/images/stbill.png';
import { LoginCheckoutWrapper } from './styles';
import { Modal, Spacing, Text } from '@dls/web';
import styled from 'styled-components';
import { navigation } from '../../middlewares/navigation-constants';
import useRootNavigation from '../../hooks/useRootNavigation';
import { CP_ERROR_CODES } from '../../config/common';
import { CP9_FLOW } from '../../types/featureFlag.types';
import { getCreditLimitErrorProps } from '../../helpers/credit-limit';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import getIsRoiMismatch from '../../helpers/is-roi-mismatch';
import CCEMisMatchError from '../CCEPrompt/CCEMisMatchErrorModal';
import { useBrowseBackPopup } from '../../hooks/useBrowseBackPopup';
import { getUIAMLoginUrl } from '../../config';
import { getIfCISMyInfoFlow } from '../../selectors';
import { useCisMyInfo } from '../../hooks/useMyInfo';
import SilverInfoModal from './SilverInfoModal';

const StyledImg = styled.img`
  width: 100%;
`;

export const LoginGuestCheckout = props => {
  const { t } = useTranslate();
  const { location } = props;
  const { allAemPages } = props.data;
  document.title = t('LGC_DOC_TITLE');

  const popHistoryStateAndNavigate = navigateTo => {
    navigate(`/${navigateTo}`, {
      replace: true
    });
  };

  const siteConfigurations = getAemComponentByName(
    allAemPages,
    'SiteConfigurations'
  );
  const isWorryFreeEnabled =
    siteConfigurations && siteConfigurations.switches.DETOX_WORRY_FREE;

  const errorScenarioCtaAction = async ctaActionType => {
    if (ctaActionType === 'primary') {
      window.location.href = errorScenarioMessage?.primaryButtonLink;
    } else {
      window.location.href = errorScenarioMessage.secondaryButtonLink;
    }
  };

  const dispatch = useDispatch();
  const {
    worryFreeStatus,
    customerExists,
    isAuthLoading,
    cart,
    selectedPlan,
    placeholderValues,
    selectedProduct,
    userInformation,
    cisInformation,
    productOrder,
    verificationData,
    order,
    roiValidatedData,
    userCISMyInfoAvailable,
    user
  } = useSelector(state => {
    return {
      worryFreeStatus: state.auth?.worryFree?.status,
      customerExists: state.auth?.worryFree?.customerExists,
      isAuthLoading: state.auth?.isLoading,
      cart: state?.cart,
      selectedPlan: state?.plan,
      placeholderValues: state?.plan?.selectedPlan?.planName,
      selectedProduct: state?.product,
      userInformation: state?.user?.information,
      cisInformation: state.user?.cis?.information,
      productOrder: state?.order?.productOrder,
      verificationData: state?.checkout?.verificationData,
      order: state?.order,
      roiValidatedData: state.iphone.validateSkuData,
      userCISMyInfoAvailable: getIfCISMyInfoFlow(state),
      user: state.user
    };
  });

  const isSimOnlyIPP = isSimOnlyPlan(selectedPlan) && selectedProduct;
  const customerId = userInformation?.clientContext?.customers?.[0]?.customerId;
  const isCISFlow = Boolean(cisInformation?.rates);
  const isCisUserLoginTypeMyInfo = Boolean(cisInformation?.$myInfo);
  const userMyInfo = cisInformation?.cusMyInfo;
  const isMyInfoLoading = Boolean(userMyInfo?.loading);

  const { id, passType, mobileNumber, mobileNumberWithoutFormat } =
    verificationData || {};

  const {
    sendOtp,
    verifyOtp,
    setWorryFreeStatus,
    resetWorryFreeStatus,
    additionalVerify,
    getCISMyInfo
  } = authActions;
  const { errorScenarios } = errorScenarioActions;

  const [authStatus, setAuthStatus] = useState(
    props?.authStatus || CHECKOUT_CONSTANT.CHECKOUT_VERIFY_ID
  );
  const roiNavigateNeeded = useRef(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cceMismatch, setCceMismatch] = useState(false);
  const [checkoutGenericErrorCode, setCheckoutGenericErrorCode] = useState('');
  const [errorScenarioMessage, setErrorScenarioMessage] = useState({});
  const { cisMyInfoData } = useCisMyInfo(user);
  const { allErrorMessage } = props.data;
  const allErrorMessagesFlattened = flattenNodes(allErrorMessage);
  const [
    displayingErrorScenarioMessage,
    setDisplayingErrorScenarioMessage
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [verifiedToCheckout, setVerifiedToCheckout] = useState(false);
  const [showSilverInfoModal, setShowSilverInfoModal] = useState(false);
  const [
    isAdditionVerificationModalOpen,
    setIsAdditionVerificationModalOpen
  ] = useState(false);

  const {
    renderBrowseBackModal,
    resetLoginAndCheckoutData,
    resetCheckoutError
  } = useBrowseBackPopup({
    pageName: navigation.LOGIN_GUEST_CHECKOUT,
    onConfirmBrowseBack: () => {
      window.history.back();
    },
    customPopstate: displayingErrorScenarioMessage
      ? () => {
          navigate(`/${CHECKOUT_CONSTANT.NAVIGATE_TO}`, { replace: true });
        }
      : null
  });

  const mainData = CHECKOUT_CONSTANT.MAINDATA;
  const setVerification = registrationDetails => {
    const passTypeText = mainData.PASSTYPESLIST.find(
      idType => idType.value === registrationDetails.passType
    );
    const payload = passTypeText
      ? { ...registrationDetails, passTypeText: passTypeText.text }
      : { ...registrationDetails, passTypeText: null };
    return {
      type: 'SET_VERIFICATION_ALL_DETAILS',
      payload
    };
  };

  const data = {
    id,
    passType,
    mobileNumber,
    mobileNumberWithoutFormat
  };
  useRootNavigation(cart);

  const getWorryFreeNavigation = () => {
    if (userCISMyInfoAvailable) {
      return navigation.CHECKOUT;
    }

    return navigation.USER_IDENTITY_CHECK;
  };

  const checkErrorScenarios = async () => {
    try {
      await dispatch(
        errorScenarios({
          isSimOnlyIPP,
          userInformation,
          productOrder,
          isAnonymous: true,
          recalculateBlock: true,
          portIn: productOrder?.type === ORDER_CONSTANT.TYPE.PORTIN,
          addedToContact: !order.addedToContact,
          selectedPlan: selectedPlan?.selectedPlan,
          validateForInfomationError: true
        })
      );
    } catch (error) {
      // To be done in the future.
    }
  };

  useEffect(() => {
    if (!productOrder) {
      return navigate('/', { replace: true });
    }
  }, [productOrder]);

  // CIS MyInfo Session flow
  useEffect(() => {
    if (isCisUserLoginTypeMyInfo && isCISFlow) {
      dispatch(
        getCISMyInfo({
          sessionToken: cisInformation?.$myInfo?.accessToken
        })
      );
    }
  }, [isCisUserLoginTypeMyInfo, isCISFlow]);

  useEffect(() => {
    if (
      cart?.checkoutErrorData !== null ||
      checkoutGenericErrorCode === 'CHECKOUT_GENERIC_ERROR'
    ) {
      const errorScenarioData = getCartError(
        cart?.checkoutErrorData || checkoutGenericErrorCode,
        allErrorMessagesFlattened
      );
      const cartErrorDataModel = getCartErrorDataModel(
        errorScenarioData,
        placeholderValues
      );
      const callResetLoginAndCheckoutData = async () => {
        await resetLoginAndCheckoutData();
      };
      callResetLoginAndCheckoutData();
      setErrorScenarioMessage(cartErrorDataModel || defaultGenericErrorModal);
      setAuthStatus(CHECKOUT_CONSTANT.CHECKOUT_GENERIC_ERROR);
      setDisplayingErrorScenarioMessage(true);
    }
  }, [cart?.checkoutErrorData, checkoutGenericErrorCode]);

  useEffect(() => {
    if (
      cart?.checkoutErrorScenarioCheckCompleted === true &&
      !cart?.checkoutOrderError
    ) {
      // Redirect to the desired page.
      // Needs to get the redirect link via configuration.
      setAuthStatus(CHECKOUT_CONSTANT.CHECKOUT_OTP_VERIFIED);

      // if silver discount info present, show modal for acknowledgement
      if (cart?.checkoutInfoData) {
        setShowSilverInfoModal(true);
      } else {
        setVerifiedToCheckout(true);
      }
    }
  }, [
    cart?.checkoutErrorScenarioCheckCompleted,
    cart?.checkoutOrderError,
    cart?.checkoutInfoData
  ]);

  const checkNeedValidateOnePassUser = () => {
    const queryParams = getUrlParams(location?.search);
    return (
      queryParams[CONSTANTS.URL_PARAMS.NEED_VALIDATE_ONE_PASS_USER] === 'true'
    );
  };
  const needValidateOnePassUser = checkNeedValidateOnePassUser();
  useEffect(() => {
    if (needValidateOnePassUser && !userInformation?.userDetails?.loginId) {
      dispatch(userActions.getUserInformation());
    }
  }, []);

  useEffect(() => {
    if (needValidateOnePassUser && userInformation?.userDetails?.loginId) {
      const isRoiMismatch = getIsRoiMismatch(
        userInformation?.userDetails?.custId,
        roiValidatedData
      );
      if (isRoiMismatch) {
        roiNavigateNeeded.current = true;
        setCceMismatch(true);
      } else {
        checkErrorScenarios();
      }
    }
  }, [userInformation]);

  useEffect(() => {
    if (
      needValidateOnePassUser &&
      userInformation?.userDetails?.loginId &&
      cart?.checkoutErrorScenarioCheckCompleted
    ) {
      if (!cart?.checkoutOrderError) {
        resetCheckoutError();

        if (cart?.checkoutInfoData) {
          setShowSilverInfoModal(true);
        } else {
          popHistoryStateAndNavigate(navigation.CHECKOUT);
        }
      } else {
        setAuthStatus(CHECKOUT_CONSTANT.CHECKOUT_GENERIC_ERROR);
        dispatch({
          type: CHECKOUT_CONSTANT.CHECKOUT_RESET_ERROR_SCENARIO_CHECK
        });
      }
    }
  }, [
    cart?.checkoutErrorScenarioCheckCompleted,
    userInformation,
    cart?.checkoutOrderError,
    cart?.checkoutInfoData,
    isAuthLoading
  ]);

  useEffect(() => {
    setIsLoading(false);

    const statusCases = async () => {
      switch (worryFreeStatus) {
        case NEW_NEW_CUSTOMER: {
          popHistoryStateAndNavigate(getWorryFreeNavigation());
          break;
        }
        case OTP_SENT:
          setAuthStatus(CHECKOUT_CONSTANT.CHECKOUT_VERIFY_OTP);
          break;
        case OTP_RESEND_SUCCESS:
          setAuthStatus(CHECKOUT_CONSTANT.CHECKOUT_VERIFY_OTP);
          break;
        case WORRY_FREE_FA_CHECK:
          setAuthStatus(
            CHECKOUT_CONSTANT.CHECKOUT_ADDITIONAL_VERIFICATION_REQUIRED
          );
          break;
        case OTP_VERIFIED:
          await checkErrorScenarios();
          break;
        case OTP_EXCEED_LIMIT_ERROR:
          setAuthStatus(CHECKOUT_CONSTANT.CHECKOUT_OTP_LIMIT_EXCEEDED);
          break;
        case OTP_MISMATCH_ERROR:
        case OTP_GENERIC_ERROR:
          setCheckoutGenericErrorCode('CHECKOUT_GENERIC_ERROR');
          break;
        case OTP_VERIFY_ERROR:
          setErrorMessage(t('VERIFICATION_OTP_INVALID_OTP_ERROR_MSG'));
          break;
        case OTP_TIMEOUT_ERROR:
          setErrorMessage(t('VERIFICATION_OTP_EXPIRED_OTP_ERROR_MSG'));
          break;
        case NEW_CUSTOMER_TO_MOBILE:
          // Redirect to the Additional Verification page.
          break;
        case WORRY_FREE_ACCOUNT_VERIFIED:
          setAuthStatus(CHECKOUT_CONSTANT.WORRY_FREE_ACCOUNT_VERIFIED);
          break;
        case ADDITIONAL_VERIFY_ERROR:
          setAuthStatus(CHECKOUT_CONSTANT.ADDITIONAL_VERIFY_ERROR);
          break;
        default:
          break;
      }
    };

    statusCases();
  }, [worryFreeStatus]);

  const renderGenericError = (errorScenarioMessage = {}) => {
    if (
      isFeatureFlagEnabled(CP9_FLOW) &&
      Object.values(CP_ERROR_CODES).includes(errorScenarioMessage?.errorCode)
    ) {
      const { navigateTo } = getCreditLimitErrorProps(errorScenarioMessage);
      return (
        <CPModal errorData={errorScenarioMessage} navigateTo={navigateTo} />
      );
    }

    return (
      <GenericError
        title={errorScenarioMessage.title}
        errorText={errorScenarioMessage.errorText}
        typeLinkText={errorScenarioMessage.ctaText}
        primaryButtonText={errorScenarioMessage.primaryButtonText}
        secondaryButtonText={errorScenarioMessage.secondaryButtonText}
        onPrimaryClick={() => {
          errorScenarioCtaAction('primary');
        }}
        onSecondaryClick={() => {
          errorScenarioCtaAction('secondary');
        }}
        footerList={errorScenarioMessage.footerList}
      />
    );
  };

  const onOtpSuccessClicked = () => {
    if (customerExists) {
      dispatch({
        type: CHECKOUT_CONSTANT.SET_CHECKOUT_FLOW,
        payload: CHECKOUT_CONSTANT.CHECKOUT_EXISTING_NEW_CUSTOMER
      });
    }
    popHistoryStateAndNavigate(navigation.CHECKOUT);
  };

  const doEmptyCart = async () => {
    if (productOrder) {
      const { productOrderId, productOrderItemId } = productOrder;

      await dispatch(
        cartActions.emptyCart({
          customerId,
          productOrderId,
          productOrderItemId
        })
      );
    }
  };

  const cceProceedClicked = async () => {
    setCceMismatch(false);

    if (roiNavigateNeeded.current) {
      await resetLoginAndCheckoutData();
      await doEmptyCart();
      navigate('/', { replace: true });
    }
  };

  const onNricVerified = registrationDetails => {
    const worryFreeParams = {
      cleanWorryFree: !registrationDetails.triggeredResend,
      mobileNumber: registrationDetails.mobileNumberWithoutFormat,
      indentType: registrationDetails.passType,
      indentValue: registrationDetails.id
    };
    const isRoiMismatch = getIsRoiMismatch(
      registrationDetails.id,
      roiValidatedData
    );
    if (isRoiMismatch) {
      setCceMismatch(true);
    } else if (!isWorryFreeEnabled) {
      dispatch(setWorryFreeStatus('NEW_NEW_CUSTOMER'));
      dispatch(setVerification(registrationDetails));
      setErrorMessage('');
    } else {
      setIsLoading(true);
      dispatch(setVerification(registrationDetails));
      setErrorMessage('');
      dispatch(sendOtp(worryFreeParams));
      dispatch(setWorryFreeStatus(worryFreeParams));
    }
  };

  const handleSilverInfoModalClose = async () => {
    setShowSilverInfoModal(false);
    await doEmptyCart();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (verifiedToCheckout && !needValidateOnePassUser) {
      onOtpSuccessClicked();
    }
  }, [verifiedToCheckout, needValidateOnePassUser]);

  const renderVerification = () => {
    return (
      <>
        <LoginCheckoutWrapper>
          <Spacing top={4}>
            <Verify
              authStatus={authStatus}
              data={{
                mainData,
                data: cisMyInfoData ? { ...cisMyInfoData } : { ...data }
              }}
              errorMessage={errorMessage}
              isLoading={isLoading}
              configs={{
                otpTimeInterval: 30,
                onePassLoginRequired: true,
                disabledFields: cisMyInfoData ? ['passType'] : []
              }}
              callbacks={{
                onNricVerified,
                onOtpVerified: registrationDetails => {
                  dispatch(resetWorryFreeStatus());
                  setIsLoading(true);
                  setErrorMessage('');
                  dispatch(
                    verifyOtp({
                      otpCode: registrationDetails.otp,
                      mobileNumber:
                        registrationDetails.mobileNumberWithoutFormat,
                      indentType: registrationDetails.passType,
                      indentValue: registrationDetails.id
                    })
                  );
                },
                onAdditionVerificationClick: registrationDetails => {
                  setIsLoading(true);
                  setErrorMessage('');
                  dispatch(
                    additionalVerify({
                      mobileNumber:
                        registrationDetails.mobileNumberWithoutFormat,
                      indentType: registrationDetails.passType,
                      indentValue: registrationDetails.id,
                      lob: 'MOB',
                      billingAccountNumber: registrationDetails.accountNumber
                    })
                  );
                },
                onOnePassLoginClick: () => {
                  window.open(
                    getUIAMLoginUrl(
                      `${navigation.LOGIN_GUEST_CHECKOUT}?${CONSTANTS.URL_PARAMS.NEED_VALIDATE_ONE_PASS_USER}=true`
                    ),
                    '_self'
                  );
                },
                onAdditionalVerificationModalClick: () => {
                  setIsAdditionVerificationModalOpen(true);
                }
              }}
              localisation={
                isWorryFreeEnabled
                  ? {
                      VERIFICATION_TITLE: 'Log in with OTP',
                      VERIFICATION_SUBTITLE: null,
                      VERIFICATION_GET_SMS_OTP: 'Get SMS OTP',
                      VERIFICATION_REQUIREMENTS:
                        'NRIC/FIN number must be the one registered to the mobile above',
                      VERIFICATION_OTP_VERIFY_WITH_ONEPASS_TEXT: null,
                      VERIFICATION_OTP_VERIFY_WITH_ONEPASS:
                        'I prefer to verify with OnePass'
                    }
                  : {
                      VERIFICATION_TITLE: 'Register your details',
                      VERIFICATION_SUBTITLE:
                        'Enter your current contact number.',
                      VERIFICATION_GET_SMS_OTP: 'Enter personal details',
                      VERIFICATION_REQUIREMENTS: null,
                      VERIFICATION_OTP_VERIFY_WITH_ONEPASS_TEXT:
                        'Existing Singtel customer? ',
                      VERIFICATION_OTP_VERIFY_WITH_ONEPASS:
                        'Log in with OnePass or Singpass'
                    }
              }
            />
          </Spacing>

          {showSilverInfoModal ? (
            <SilverInfoModal
              isModalOpen={showSilverInfoModal}
              onClose={handleSilverInfoModalClose}
              onCtaClick={() => {
                setShowSilverInfoModal(false);
                onOtpSuccessClicked();
              }}
            />
          ) : null}

          <Modal
            data-testid="howDoIFindModal"
            visible={isAdditionVerificationModalOpen}
            title={t('ADDITIONAL_VERIFICATION_WHERE_TO_FIND')}
            onClose={() => setIsAdditionVerificationModalOpen(false)}
          >
            <Modal.Content>
              <Spacing bottom={2}>
                <Text type="body">
                  {t('ADDITIONAL_VERIFICATION_WHERE_TO_FIND_DESCRIPTION')}
                </Text>
              </Spacing>
              <Spacing bottom={1}>
                <StyledImg src={ImageStBill} alt="Image of Singtel Bill" />
              </Spacing>
              <StyledImg src={ImageMsta} alt="Image of My Singtel App" />
            </Modal.Content>
          </Modal>
        </LoginCheckoutWrapper>
        {cceMismatch && (
          <CCEMisMatchError
            proceedClicked={cceProceedClicked}
            isLoggedInUser={roiNavigateNeeded.current}
          />
        )}
        {(isAuthLoading || cart.loading || isMyInfoLoading) && (
          <LoadingOverlayComponent />
        )}
        {renderBrowseBackModal()}
      </>
    );
  };

  switch (authStatus) {
    case CHECKOUT_CONSTANT.CHECKOUT_GENERIC_ERROR:
      return renderGenericError(errorScenarioMessage);

    default:
      return renderVerification();
  }
};

export default LoginGuestCheckout;
